@import "./reset.css";
@import "./normalize.css";
@import "./fonts";
@import "./main.css";

.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #28292b;
  z-index: 1000;

  &-container {
    padding: 0 16px;
    min-height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.container {
  padding: 80px 16px 12px;
}
.content-wrapper {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;

  .title {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: white;
    margin-bottom: 12px;
    font-family: 'Roboto', 'sans-serif';
  }

  .subtitle {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #BABABA;
    margin-bottom: 24px;
  }
}

.cards-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 12px;

  .card {
    width: 100%;
    max-width: calc(50% - 6px);
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 12px;
    padding-bottom: 9px;
    background: #28292b;
    height: 140px;
    cursor: pointer;

    &:nth-child(odd) {
      margin-right: 12px;
    }

    .option:before {
      content: '';
      position: absolute;
      bottom: -3px;
      z-index: -4;
      width: 100%;
      padding-top: 100%;
      background: #28292b;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      box-sizing: border-box;
    }

    .image-wrapper {
      height: 100%;
      overflow: hidden;
      margin: 0 auto;

      .image {
        width: 100%;
        height: auto;
        object-fit: contain;
        display: block;
        max-width: 166px;
        margin-left: auto;
        margin-right: auto;
        max-height: 101px;
        padding-top: 11px;
      }
    }

    .button-box {
      position: absolute;
      bottom: 0;
      height: 36px;
      width: 100%;
      background-color: #0045FF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #1d1d1d;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      .button-text {
        font-size: 17px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 2px;
        color: white;
        margin-right: 8px;
      }
    }
  }
}

.privacy-block {
  .privacy {
    color: #939394;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    font-family: 'RobotoLight', 'sans-serif';
  }

  .white-text {
    color: white;
    text-decoration: none;
  }
}

.link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  opacity: 0;
}

@media (min-width: 601px) {
 .content-wrapper {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   .title {
     font-size: 32px;
     line-height: 40px;
   }

   .subtitle {
     margin-bottom: 72px;
   }

   .cards-wrapper {
     flex-wrap: nowrap;

     .card {
       max-width: 190px;
       margin-right: 12px;
       height: 167px;
       overflow: visible;

       &:last-child {
         margin-right: 0;
       }

       .image-wrapper {
         overflow: visible;

         .image {
           max-height: 164px;
           top: -35px;
           position: relative;
         }
       }
     }
   }
 }
}

@media (min-width: 768px) {
  .content-wrapper {
    max-width: 584px;
  }
}

@media (min-width: 1366px) {
  .content-wrapper {
    max-width: 818px;
  }
}
