@mixin font-face($font-family, $woff-path, $ttf-path) {
  @font-face {
    font-family: #{$font-family};
    src: url('#{$woff-path}') format('woff'),
    url('#{$ttf-path}') format('truetype');
    font-display: swap;
  }
}

@include font-face('RobotoBold', '../../public/fonts/Roboto-Bold.woff', '../../public/fonts/Roboto-Bold.ttf');
@include font-face('Roboto', '../../public/fonts/Roboto-Medium.woff', '../../public/fonts/Roboto-Medium.ttf');
@include font-face('RobotoLight', '../../public/fonts/Roboto-Light.woff', '../../public/fonts/Roboto-Light.ttf');
